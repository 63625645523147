body, html {
	text-align: left; 
    font-family: "Open Sans", sans-serif;
	font-size: 90%;
	color: #444;
}

body {
    margin: 1em;
}

h1, h2, h3, h4 {
	color: #596979;
}

.name {
	margin-top: -5px;
	margin-bottom: 6px;
}

.row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
 }

.column {    
	/* display: flex; */
	flex-direction: column;
	flex-basis: 33%;
}

.column-nowrap {    
	display: flex;
	flex-wrap: nowrap;
	white-space: nowrap;
	flex-direction: column;
}


.double-column {
	display: flex;
	flex-direction: column;
	flex-basis: 100%;
}

@media screen and (min-width: 800px) {
	.column {
		flex: 1
	}

	.double-column {
		flex: 2
	}
}

.right {
	float: right;
	text-align: right;
	margin-left: auto;
}

.center {
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}

.card {
    background: white;
    /* border: 1px solid #999; */
    margin: 0.5em;
    padding: 1em;
    box-shadow: 0 2px 3px rgba(10,10,10,.1),0 0 0 1px rgba(10,10,10,.1);
    border-top-right-radius: 8px;
}

.card div {
    color: #444;
}

.card > ul {
	margin-top: 2em;
	margin-left: -2em;
}

.nowrap {
	white-space: nowrap;
}

#skillsContainer {
	position: relative;
}

#skills {
	margin-top: 1.7em;
	min-width: 350px;
	/* position: fixed; */
	/* top: 0px; */
}

.gradient {
    color: #666;
    border-bottom: 1px solid #ccddcc;
    background: linear-gradient(#FFF,#EEE);
    text-shadow: 1px 1px 0 white;
    border: 1px solid #bbb;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-radius: 3px;
    border-top-right-radius: 8px;
    margin-top: -13px;
    margin-left: -14px;
    width: 104%;
    padding-left: 13px;
    margin-bottom: 10px;
    padding: 7px;
}

h3.title {
	position: relative;
	margin: -14px;
	padding: 0.6em;
	font-size: 90%;
    color: #666;
	border-bottom: 1px solid #ccddcc;
	background: linear-gradient(#FFF,#EEE);
	text-shadow:1px 1px 0 white;
	border: 1px solid #bbb;
	border-top: 1px solid #ccc;
	border-left: 1px solid #ccc;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
    border-radius: 3px;
	border-top-right-radius: 8px;
}

h3.title div {
	display: block;
	float: right;
	font-weight: normal;
	font-size: 90%;
}

h4.title {
	position: relative;
	margin: -14px;
	font-size: 90%;
	color: #666;
	border-bottom: 1px solid #ccddcc;
	background: #EEE;
	background-image: -moz-linear-gradient(top, #FFF, #EEE);
	background-image: -webkit-linear-gradient(top, #FFF, #EEE);
	background-image: -moz-linear-gradient(top, #FFF, #EEE);
	text-shadow:1px 1px 0 white;
	padding: 0.5em;
	border: 1px solid #bbb;
	border-top: 1px solid #ccc;
	border-left: 1px solid #ccc;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	border-top-right-radius: 8px;
}

.title span {
	float: right;
	font-weight: normal;
	text-align: right;
	position: absolute;
	right: 1em;
}

.location {
	padding: 1em;
	color: #AAA;
	box-shadow: 0px 2px 3px rgba(10,10,10,.1), 0 1 0 1px rgba(10,10,10,.1);
	border-bottom-left-radius: 28px;
	float: right;
	position: relative;
	/* right: 4px; */
	white-space: nowrap;
	/* background: pink; */
	/* width: 20%; */
	text-align: right;
	margin-right: -1em;
	margin-top: 9px;
	/* line-height: 26%; */
	/* color: #666; */
	/* border-bottom: 1px solid #ccddcc; */
	/* background: linear-gradient(#EEE,#FFF); */
	/* text-shadow: 1px 1px 0 white; */
	/* border-top: 0px solid #ccc; */
	/* border-left: 1px solid #ccc; */
	/* border-top: 0px solid white; */
	font-size: 100%;
}

.skills {
    border-collapse: collapse;
    width: 100%;
    padding: 1em;
}

.active {
	background: #FFFF00;
}

a {
	color: #666677;
	-webkit-transition: opacity 0.2s linear;
    opacity: 1;
}

.highlightbg {
	background: yellow;
	font-weight: bold;
}

a.noline {
   text-decoration: none;
   color: #000000;
}

a.noline:hover {
	color: #003300;
	-webkit-transition: opacity 0.2s linear;
    opacity: .5;
}

#contact {
	float: right;
	margin-top: 3em;
}

fieldset {
	border: 1px solid #CFCFC5; 
	padding: 1em;
	border-radius:6px;
	-moz-border-radius:6px;
	-webkit-border-radius:6px;
}

.rounded {
	background: #EEE;
	background-image: -moz-linear-gradient(top, #FFF, #EEE);
	background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0, #FFF),color-stop(1, #EEE));
	background-image: -webkit-linear-gradient(top, #FFF, #EEE);
	background-image: -moz-linear-gradient(top, #FFF, #EEE);
	background-image: -ms-linear-gradient(top, #FFF, #EEE);
	background-image: -o-linear-gradient(top, #FFF, #EEE);
	border: 1px solid #bbb;
	border-top: 1px solid #ccc;
	border-left: 1px solid #ccc;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	padding: 5px;
	margin-top: 15px;
	margin-bottom: 5px;
}	

.normal {
	font-weight: normal;
	color: #999;
}

.fas {
    opacity: 0.5;
    color: #aaa;
}

.tinyText {
	font-size: 85%;
	letter-spacing: 5px;
	margin-right: 9px;
	text-align: center;
}

.tinyText:after {
	content: "";
	display: inline-block;
	width: 100%;
}

#controlPanelContainer {
	float: right;
	position: relative;
	width: 100%;
    height: 87%;
    margin-top: 1em;
	margin-right: 1em;
}

#controlPanel {
	position: absolute;
	bottom: 0;
	right: 0;
}

div label {
    vertical-align: middle;  
	height: 50px;
}

#experienceDetailLevel {
	vertical-align: middle;
	background: lightgoldenrodyellow;
	width: 6em;
}

.experienceDetails0 {
	display: none;
}

.experienceDetails1 {
	display: block;
}

.experienceDetails2 {
	display: block;
}


/*** custom checkboxes ***/

/* input[type=checkbox] { display:none; } /* to hide the checkbox itself */
/* input[type=checkbox] + label:before {
  font-family: FontAwesome;
  display: inline-block;
}

/* input[type=checkbox] + label:before { content: "\f096"; } /* unchecked icon */
/* input[type=checkbox] + label:before { letter-spacing: 10px; } /* space between checkbox and label */

/* input[type=checkbox]:checked + label:before { content: "\f046"; } /* checked icon */
/* input[type=checkbox]:checked + label:before { letter-spacing: 5px; } /* allow space for check mark */
